<template>
  <b-card
    title="รายงานการจ่ายโปรโมชั่น"
    no-body
  >
    <b-card-body>
      <!-- <div class="d-flex justify-content-between  flex-wrap"> -->
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="เบอร์โทร / ยูส"
              label-for="mc-first-name"
            >
              <b-form-input
                id="mc-first-name"
                v-model="tableQuery.user"
                placeholder="เบอร์โทร / ยูส"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="วันที่"
              label-for="mc-country"
            >
              <b-row>
                <b-col md="6">
                  <flat-pickr
                    v-model="tableQuery.startdate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
                <b-col md="6">
                  <flat-pickr
                    v-model="tableQuery.enddate"
                    class="form-control"
                    :config="{ enableTime: false,dateFormat: 'Y-m-d', time_24hr: true, minuteIncrement:1 }"
                  />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->
        </b-row>
        <b-row>
          <b-col md="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="fetchReport"
            >
              ดูรายงาน
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="clearSearch"
            >
              เคลียร์
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <!-- </div> -->
    </b-card-body>

    <b-table
      ref="memberWinlossTable"
      striped
      hover
      responsive
      class="position-relative"
      :busy.sync="isBusy"
      :per-page="tableQuery.page_size"
      :current-page="tableQuery.page"
      :items="items"
      :fields="fields"
      :total-rows="totalRows"
    >
      <template #cell(user)="data">
        <span v-if="data.item.phoneno.indexOf('X') < 0">{{ data.item.user.phoneno }}<br></span>
        {{ data.item.user.username }}
        <b-badge v-if="data.item.user.level != 'MEMBER'" :variant="'light-danger'">
          {{ data.item.user.level }}
        </b-badge>
      </template>
      <template #cell(fullname)="data">
        {{ `${data.item.user.fname} ${data.item.user.lname}` }}
      </template>
      <template #cell(detail)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'ซ่อน' : 'แสดง'}} รายละเอียด
        </b-button>
      </template>
      <template #row-details="row">
        <b-table
          :items="row.item.winloss"
          :fields="detailFields"
        />
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="tableQuery.page_size"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="tableQuery.page"
          :total-rows="totalRows"
          :per-page="tableQuery.page_size"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BButton, BCardBody, BRow, BCol, BForm, BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Cleave from 'vue-cleave-component'
import moment from 'moment'

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInput,
    // Cleave,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    // vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      isBusy: false,
      pageOptions: [100, 200, 500, 1000],
      totalRows: 0,
      tableQuery: {
        page: 1,
        page_size: 100,
        startdate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        enddate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      },
      fields: [
        { key: 'user', label: 'ลูกค้า' },
        { key: 'fullname', label: 'ชื่อ - นามสกุล' },
        {
          key: 'deposit_amount',
          label: 'ฝากรวม',
          tdClass: 'td-currency',
          formatter: val => this.numberWithCommas(`${val}`),
        },
        {
          key: 'promotion_amount',
          label: 'รับโปรรวม',
          tdClass: 'td-currency',
          formatter: val => this.numberWithCommas(`${val}`),
        },
        {
          key: 'withdraw_amount',
          label: 'ถอนรวม',
          tdClass: 'td-currency',
          formatter: val => this.numberWithCommas(`${val}`),
        },
        {
          key: 'winloss_amount',
          label: 'ลูกค้า +/- รวม',
          tdClass: val => (val > 0 ? 'td-currency green' : 'td-currency red'),
          formatter: val => this.numberWithCommas(`${val}`),
        },
        {
          key: 'detail',
          label: 'รายละเอียด',
        },
      ],
      detailFields: [
        {
          key: 'report_date',
          label: 'วันที่',
          formatter: val => moment(val).format('DD/MM/YYYY'),
        },
        {
          key: 'stat_provider',
          label: 'ค่ายเกมส์',
        },
        {
          key: 'winloss',
          label: 'ลูกค้า +/- รวม',
          tdClass: val => (val > 0 ? 'td-currency green' : 'td-currency red'),
          formatter: val => this.numberWithCommas(`${val}`),
        },
      ],
      /* eslint-disable global-require */
      items: [
      ],
      /* eslint-disable global-require */
      status: [{
        1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    // getBankOptions() {
    //   return this.bankListOptions
    // },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length

    this.fetchReport()
    // this.fetchMember()
  },
  methods: {
    async fetchReport() {
      try {
        const { data: { success, winloss } } = await this.$http.post('report/member-winloss', this.tableQuery)
        if (success) {
          this.items = winloss
          this.totalRows = this.items.length
        }
      } catch (err) {
        console.log(err)
      }
    },
    clearSearch() {
      this.tableQuery = {
        page: 1,
        page_size: 100,
        startdate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        enddate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      }
      this.$refs.membertable.refresh()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.td-currency {
  text-align: right;
}
.td-currency.green {
  color: #149914 !important;
}
.td-currency.red {
  color: #ff0000 !important;
}
.td-currency.orange {
  color: #b87200 !important;
}
.text-center {
  text-align: center;
}
</style>
